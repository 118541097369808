<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    floating
    clipped
    width="280"
    class="overflow-visible elevation-8"
    color="menu"
  >
    <v-list dense flat class="pa-0 mt-md-15 mt-4">
      <template v-for="(item, i) in items">
        <v-list-item
          v-if="!item.spacer && (item.onlineOnly !== true || unlocked)"
          :key="`tile-${i}`"
          :to="item.to"
          :value="item.value"
          active-class="white--text"
          color="primary"
          exact
          class="px-10"
          v-on="item.click && {
            'click': item.click
          }"
        >
          <v-list-item-avatar
            height="24"
            width="24"
          >
            <v-icon
              style="color: inherit"
              right
              v-text="item.icon"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-badge
              v-if="item.badge != undefined"
              offset-x="30"
              offset-y="17"
            >
              <template #badge>
                <span v-text="item.badge" />
              </template>
              <v-list-item-title v-text="item.text" />
            </v-badge>
            <v-list-item-title v-else v-text="item.text" />
          </v-list-item-content>
        </v-list-item>

        <div
          v-else
          :key="`divider-${i}`"
          class="my-auto"
        />
      </template>
    </v-list>
    <v-list dense flat class="my-md-15 mt-4">
      <template v-for="(item, b) in helper">
        <v-list-item
          :key="`tile-${b}`"
          :to="item.to"
          :value="item.value"
          color="primary"
          exact
          class="px-10"
        >
          <v-list-item-avatar
            height="24"
            width="24"
          >
            <v-icon
              style="color: inherit"
              right
              v-text="item.icon"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <div class="d-flex flex-row justify-center my-md-15 my-5">
      <v-btn v-for="(s, x) in socials" :key="x" icon :href="s.to" target="_blank">
        <v-icon>
          {{ s.icon }}
        </v-icon>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapMutations,
    mapActions,
    mapState,
  } from 'vuex'
  import { mdiGamepadCircleOutline, mdiHomeOutline, mdiTrophyOutline } from '@mdi/js'
  export default {
    name: 'CoreDrawer',
    data: () => {
      return {
        socials: [
          { icon: 'mdi-twitter', to: 'https://twitter.com' },
          { icon: 'mdi-facebook', to: 'https://twitter.com' },
          { icon: 'mdi-youtube', to: 'https://twitter.com' },
          { icon: 'mdi-instagram', to: 'https://twitter.com' },
          { icon: 'mdi-reddit', to: 'https://twitter.com' },
          { icon: 'mdi-discord', to: 'https://twitter.com' },
        ],
        helper: [
          {
            icon: 'mdi-information-variant',
            text: 'Help',
            to: '/1234',
          },
          {
            icon: mdiHomeOutline,
            text: 'Conditions',
            to: '/12344',
          },
          {
            icon: mdiHomeOutline,
            text: 'Confidentiality',
            to: '/123444',
          },
        ],
        varSelect: undefined,
      }
    },
    computed: {
      ...mapState('wallet', ['unlocked']),
      ...mapState('platform', ['mainHost']),
      ...mapState('downloads', {
        downloadDrawer: 'drawer',
      }),
      liveCount () {
        var n = 0
        return n
      },
      totalFree () {
        var creds = this.creditList[this.curNode] || 0
        return creds - this.totalLocked
      },
      selectRegions () {
        // var nodes = [{ text: 'All', value: 0 }, { text: 'Brazil', value: 'br1' }, { text: 'North America', value: 'na1' }, { text: 'Europe West', value: 'euw1' }, { text: 'Korea', value: 'kr' }]
        return this.regions
      },
      selectNodes () {
        var nodes = [{ text: 'GGT Network', value: 0 }]
        this.nodes.forEach(n => {
          var creds = this.creditList[n.addr] || 0
          nodes.push({ text: n.tag + ' - ' + creds + ' GGT', value: n.addr })
        })
        console.log('nnnn', this.nodes)
        return nodes
      },
      curRegionSelect: {
        get () {
          return this.curRegion
        },
        set (val) {
          this.setRegion(val)
        },
      },
      curNodeSelect: {
        get () {
          return this.curNode
        },
        set (val) {
          // this.setDrawer(val)
        },
      },
      drawer: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
      items () {
        return [
          {
            icon: mdiHomeOutline,
            text: 'Home',
            to: '/',
          },
          // {
          //   icon: 'mdi-fire',
          //   text: 'Store',
          //   to: '/store',
          // },
          /* {
            icon: mdiGamepadCircleOutline,
            text: 'Nodes',
            to: '/nodes',
          }, */
          {
            icon: mdiGamepadCircleOutline,
            text: 'Esports',
            to: '/esports',
          },
          {
            icon: mdiGamepadCircleOutline,
            text: 'Game Streams',
            to: '/gamestreams',
          },
          {
            icon: mdiGamepadCircleOutline,
            text: 'Twitch Streams',
            to: '/twitchstreams',
          },
          {
            icon: mdiGamepadCircleOutline,
            text: 'Tournaments',
            to: '/tournaments',
          },
          {
            icon: mdiGamepadCircleOutline,
            text: 'Profiles',
            to: '/profiles',
          },
          {
            icon: mdiGamepadCircleOutline,
            text: 'Invites',
            to: '/invites',
          },
          {
            icon: mdiGamepadCircleOutline,
            text: 'Results',
            to: '/results',
          },
          {
            icon: mdiGamepadCircleOutline,
            text: 'Oracles',
            to: '/oracles',
          },
          {
            icon: mdiTrophyOutline,
            text: 'Settings',
            to: '/settings',
            onlineOnly: true,
          },
          // { spacer: true },
          // {
          //   icon: 'mdi-download',
          //   text: 'Downloads',
          //   value: this.downloadDrawer,
          //   click: () => {
          //     this.$vuetify.breakpoint.smAndDown && this.setDrawer(false)
          //     this.toggleDownloadDrawer()
          //   },
          // },
          // {
          //   icon: 'mdi-settings',
          //   text: 'Settings',
          //   to: '/settings',
          // },
        ]
      },
    },
    watch: {
      nodes () {

      },
    },
    async mounted () {
      const theme = localStorage.getItem('dark_theme')
      if (theme) {
        if (theme === 'true') {
          this.$vuetify.theme.dark = true
        } else {
          this.$vuetify.theme.dark = false
        }
      }
      this.getGames()
      this.initW3()

      // get nodes
      /* var presponse = await this.$http.get(this.mainHost + '/nodes')
      console.log('nodes', presponse.data)
      this.setNodes(presponse.data) */
      console.log('do mount')
    },
    methods: {
      ...mapActions('platform', ['getGames']),
      ...mapActions('wallet', ['initW3']),
      ...mapMutations('app', ['setDrawer']),
      ...mapMutations('downloads', {
        toggleDownloadDrawer: 'toggleDrawer',
      }),
      pickRegion (addr) {
        console.log('zz')
      },
      pickNode (addr) {
        this.setNode(addr)
        // this.$router.push('/nodes/' + addr)
      },
      toggleThemeDarkMode () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString())
      },
    },
  }
</script>

<style>
  .v-navigation-drawer__content::-webkit-scrollbar {
    overflow-y: hidden !important;
  }
</style>
